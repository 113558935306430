@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Centra;
  src: url('./assets/font/CentraNo2-Book.ttf');
  font-weight: 400;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: 'Centra', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width:1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success, p.danger {
  font-weight: 400;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding: 15px 0;
  font-size: 16px;
  z-index: 9999;
  transition: top 0.5s ease-in-out;
}

p.success {
  background-color: #4CAF50;
  color: white;
}

p.danger {
  background-color: #f44336;
  color: white;
}

/* Navbar CSS */
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9998;
  transition: 0.32s ease-in-out;
}

nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}

nav.navbar a.navbar-brand {
  width: 9%;
}

@media (max-width: 992px) {
  nav.navbar a.navbar-brand {
    width: 18%;
    display: block;
    margin-inline: auto;
  }
}

nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.6;
}

nav.navbar .navbar-collapse {
  transition: background-color 0.3s ease-in-out;
  background-color: transparent;
}

@media (max-width: 992px) {
  nav.navbar .navbar-nav .nav-link.navbar-link{
    padding-block: 5px;
  }
  span.navbar-text {
    padding: 20px 0;
    justify-content: center;
  }

  nav.navbar .navbar-collapse.show {
    background-color: #121212;
  }
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 14px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}

.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}

.social-icon a:hover::before {
  transform: scale(1);
}

.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%) hue-rotate(346deg) brightness(95%) contrast(86%);
}

.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}

.navbar-text button span {
  z-index: 1;
}

.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.navbar-text button:hover {
  color: #121212;
}

.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}

nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}

nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}

nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: '';
  z-index: 2;
  transition: all 300ms linear;
}

nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}

nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/* Banner CSS */
.banner {
  margin-top: 0;
  padding: 180px 0 100px 0;
  background-image: url('./assets/img/banner-bg.png');
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 480px) {
  .banner {
    padding: 135px 0 100px 0;
  }
}

.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(90.21deg, rgba(78, 74, 200, 0.5) -5.91%, rgba(40, 34, 206, 0.734) 111.58%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 30px;
  display: inline-block;
  transition: transform 0.3s ease;
}

.banner .tagline:hover {
  transform: scale(1.05);
}

.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}

.banner p {
  color: #B8B8B8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}

.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}

.banner button:hover svg {
  transform: scale(1.2);
}

.banner img {
  animation: updown 3s linear infinite;
}

@keyframes updown {
  0% {
    transform: translateY(-20px);
  }

  50% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(-20px);
  }
}

.txt-rotate>.wrap {
  border-right: 0.08em solid #666;
}

.wrap {
  font-size: 50px;
  background-image: linear-gradient(to right, #5876d1, #1e90ff);
  /* Blue gradient */
  -webkit-background-clip: text;
  /* Required for some browsers like Safari */
  background-clip: text;
  /* For other browsers */
  color: transparent;
  /* Make the text color transparent so the gradient shows */
}

@media (max-width: 480px) {
  .wrap {
    font-size: 25px;
  }
}

/* Skills */
.skill {
  padding: 100px 0 50px 0;
  position: relative;
}

.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.skill h2 {
  font-size: 45px;
  font-weight: 700;
}


.skill .nav.nav-pills {
  width: 72%;
  margin: 50px auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}

.skill .nav.nav-pills .nav-item {
  width: 33.33333%;
  height: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.skill .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #365daa -5.91%, #1f07be 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.skill .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.skill .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
  height: 100%;
}

.nav-link#skills-tabs-tab-first {
  height: 100%;

  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}

.nav-link#skills-tabs-tab-first::before {
  border-radius: 50px 0 0 50px;
}

.nav-link#skills-tabs-tab-second {
  height: 100%;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.nav-link#skills-tabs-tab-third {
  height: 100%;

  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 50px 50px 0;
}

.nav-link#skills-tabs-tab-third::before {
  border-radius: 0 50px 50px 0;
}

@media (max-width: 767px) {
  .skill h2 {
    font-size: 35px;
  }

  .skill .nav.nav-pills {
    width: 90%;
  }

  .skill .nav.nav-pills .nav-item {
    width: 100%;
  }

  .nav-link#skills-tabs-tab-first {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 55px 55px 0px 0px;
  }

  .nav-link#skills-tabs-tab-first::before {
    border-radius: 55px 55px 0px 0px;
  }

  .nav-link#skills-tabs-tab-second {
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    border-right: 1px solid rgba(255, 255, 255, 0.5);
  }

  .nav-link#skills-tabs-tab-third {
    border: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 0 0px 50px 55px;
  }

  .nav-link#skills-tabs-tab-third::before {
    border-radius: 0 0px 50px 55px;
  }
}

.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}

.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/* Projects CSS */
.project {
  padding: 80px 0;
  position: relative;
  background: linear-gradient(180.21deg, #171718 -5.91%, #210169 111.58%);
}

.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  margin: 0 auto 70px auto;
}

.proj-imgbx,
.proj-imgbx2 {
  position: relative;
  height: 300px;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}

.proj-imgbx img, .proj-imgbx2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.proj-imgbx2::before,
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #164577 -5.91%, #1809b8 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.proj-imgbx:hover::before,
.proj-imgbx2:hover::before {
  height: 100%;
}

.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
  padding: 10px;
}

.proj-imgbx2:hover .proj-txtx,
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}

.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}

.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}

.projects-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  transition: max-height 0.5s ease-in-out;
}

.projects-grid:not(.expanded) {
  max-height: 780px;
  overflow: hidden;
}

.show-more-container {
  position: relative;
  text-align: center;
  margin-top: -50px;
}

.fade-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 120px;
  background: linear-gradient(to bottom, rgba(138, 138, 138, 0) 0%, #210169 98%);
  pointer-events: none;
}

.show-more-btn {
  padding: 12px 64px;
  bottom: -30px;
  font-size: 18px;
  font-weight: 600;
  background: linear-gradient(70deg, #9e9e9e4b, #7575756e);
  color: white;
  border: solid white 1px;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.9s ease-in-out, transform 0.2s ease-in-out;
  position: relative;
  z-index: 2;
}

.show-more-btn:hover {
  transform: scale(1.05);
}

@media (max-width: 400px) {
  .project h2 {
    font-size: 35px;
  }

  .proj-txtx h4 {
    font-size: 24px;
  }
  
  .proj-txtx span {
    font-size: 12px;
  }

  .projects-grid:not(.expanded) {
    max-height: 2060px;
  }
}

.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

/* Work Experience CSS */
.work-experience {
  padding: 80px 0;
  position: relative;
  background: linear-gradient(70.21deg, #01081b -5.91%, #0b183c 111.58%);
}

.work-experience h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
  margin: 0 auto 30px auto;
}

.pills-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.work-experience .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 50px;
  border: 1px solid rgba(255, 255, 255, 1);
  padding: 10px 0;
  color: #fff;
  width: 180px;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  margin: 20px auto;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.work-experience .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #365daa -5.91%, #1f07be 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}

.work-experience .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}

.work-experience .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}

.experience-container {
  max-width: 600px;
  font-size: 16px;
  border-radius: 15px;
  padding: 20px 30px;
  width: 90%;
  margin: 20px auto;
  color: #e3e0e0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.experience-container h3 {
  background: linear-gradient(180.21deg, #d4d3d7 -5.91%, #9fa0a2 111.58%);
  -webkit-background-clip:   text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;
}

.experience-container p {
  font-weight: 600;
  border: 1px solid #bbbabd9c;
  border-radius: 15px;
  align-items: center;
  font-size: 15px;
  padding-block: 10px;
  max-width: 100%;
  margin: 10px auto 20px;
}

.work-entry.swe .experience-container {
  background: linear-gradient(90.21deg, #3505b9 -1.91%, #384361 101.58%);
}

.work-entry.step2 .experience-container {
  background: linear-gradient(240.21deg, #220233 -5.91%, #43229e 111.58%);
}

.work-entry.step1 .experience-container {
  background: linear-gradient(45.21deg, #033f55 -5.91%, #424d6b 111.58%);
}

.work-entry.xav .experience-container {
  background: linear-gradient(180.21deg, #02395c -5.91%, #0d0f85 111.58%);
}

.experience-container ul {
  list-style-type: none;
  padding-left: 1rem;
}

.experience-container ul li {
  font-weight: 500;
  position: relative;
  padding-left: 40px;
  margin-bottom: 10px;
  line-height: 1.6;
  text-align: left;
}

.experience-container ul li::before {
  content: '';
  height: .4em;
  width: .4em;
  background: linear-gradient(140.21deg, #d4d3d7 -5.91%, #2c354b 111.58%);
  display: block;
  position: absolute;
  transform: rotate(45deg);
  top: .5em;
  left: 0;
}

@media (max-width: 400px) {
  .work-experience h2 {
    font-size: 35px;
  }

  .work-experience .nav.nav-pills .nav-link {
    width: 150px;
    margin: 10px auto;
    font-size: 14px;
  }
  
  .pills-container {
    gap: 10px;
  }
}

/* Certificates */
.certificate {
  padding: 100px 0 50px 0;
  position: relative;
  background: linear-gradient(70.21deg, #201b2b -5.91%, #1a191d 111.58%);
}

.cert-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}

.certificate h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 25px;
}

.certificate .cert-slider .item img {
  margin: 0 auto;
  display: block;
  width: 45%;
}

.certificate .cert-slider .item h5 {
  margin-top: 20px;
  font-size: 1rem;
  color: #fff;
}
@media (max-width: 480px) {
  .cert-bx {
    padding: 60px 20px;
  }

  .certificate h2 {
    font-size: 35px;
  }
}

/* Contact Form CSS */
.contact {
  background: linear-gradient(45.21deg, #24047d -5.91%, #2f55bd 111.58%);
  padding: 60px 0 130px 0;
}

.contact img {
  width: 92%;
}

.contact h2 {
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
}

.px-1 {
  text-align: left;
}

.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}

@media (max-width: 480px) {
  .contact h2 {
    font-size: 35px;
  }
  
  .contact form input,
  .contact form textarea {
    display: block;  
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 8px;
  }

  .contact form button{
    display: block;
    margin-inline: auto;
  }
}

.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}

.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}

.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}

.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 5px;
  position: relative;
  transition: 0.3s ease-in-out;
}

.contact form button span {
  z-index: 1;
  position: relative;
}

.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  border-radius: 5px;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}

.contact form button:hover::before {
  width: 100%;
}

/* Footer CSS */
.footer {
  padding: 30px 0 10px 0;
  background-image: url('./assets/img/footer-bg.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.footer img {
  width: 22%;
}

.copyright {
  font-weight: 400;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.footer-text {
  font-weight: 500;
  font-size: 14px;
  color: #B8B8B8;
  letter-spacing: 0.5px;
  background: linear-gradient(90.21deg, rgba(109, 85, 133, 0.359) -5.91%, rgba(53, 3, 119, 0.409) 111.58%);
  border-radius: 10px;
  margin-top: 10px;
  text-align: center;
  padding: 10px 30px;
  transition: transform 0.3s ease;
  border: 0.1px solid white;
}

.footer-text:hover {
  transform: scale(1.05);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}